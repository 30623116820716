import React from 'react';
import { data } from '../data/data';
import logo from '../logo.svg';

const Footer = () => {
  const year = new Date();
  return (
    <footer id='contact' className='bg-light'>
      <div className='container'>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='s-m-40'></div>
            <a href='/' aria-label={data.name[0]}>
              <img
                src={logo}
                width={200}
                height={64}
                alt={data.name[0]}
                style={{ filter: 'grayscale(100%)' }}
              />
            </a>
            <div className='s-40'></div>
            <div className='s-m-40'></div>
            <p className='c-dark'>
              <a href='/'>
                @{year.getFullYear()} - {data.name[0]}
              </a>
              . Усі права захищено
            </p>
          </div>
          <div className='col-lg-6 tr'>
            <div className='s-20'></div>
            <address className='c-dark'>
              Громадська організація «{data.project[0]}»
              <br />
              ЄДРПОУ: {data.id}
            </address>
            <div className='s-20'></div>
            <a href='/privacy-policy' className='c-dark'>
              Політика конфіденційності
            </a>
          </div>
        </div>
        <div className='s-30'></div>
      </div>
    </footer>
  );
};

export { Footer };
